/*
-----------------------------------------------------------------------
	Guide Lines CSS
-----------------------------------------------------------------------
*/

.guide-intro {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 40px 250px;
}

.guide-text {
    text-align: right;
    display: block;
    font-size: 20px;
    line-height: 2;
}

/* Button */
.btn-guide {
    font-size: 20px;
    letter-spacing: 2px;
    text-decoration: none;
    text-transform: uppercase;
    color: #000;
    cursor: pointer;
    border: 3px solid;
    padding: 0.25em 0.5em;
    box-shadow: 1px 1px 0px 0px, 2px 2px 0px 0px, 3px 3px 0px 0px,
        4px 4px 0px 0px, 5px 5px 0px 0px;
    position: relative;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
}

.btn-guide:active {
    box-shadow: 0px 0px 0px 0px;
    top: 5px;
    left: 5px;
}

@media (min-width: 768px) {
    .btn-guide {
        padding: 0.25em 0.75em;
    }
}

// Responsive

@media (max-width: 800px) {
    .guide-intro {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 10px 20px;
    }

    .guide-text {
        text-align: right;
        display: block;
        font-size: 18px;
        line-height: 1.8;
    }
}
